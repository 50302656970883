// saga.js
import { all, takeEvery, put } from 'redux-saga/effects';
import SuperFetch from '@iso/lib/helpers/superFetch';

import responseActions from './actions';

const DEFAULT_QUERY = {
  limit: 10,
  skip: 0,
  populate: [],
  sort: '_id',
  direction: 'desc',
};

function* getResponses({ payload }) {
  try {
    const query = {
      ...DEFAULT_QUERY,
      ...payload,
    };

    const [responses, total] = yield all([
      SuperFetch.get('/responses', query),
      SuperFetch.get('/responses/count', query),
    ]);

    yield put(responseActions.setResponses({
      responses,
      total: total.count,
    }));
  } catch (err) {
    yield put({
      type: responseActions.RESPONSE_ERROR,
      payload: err.message,
    }); 
  }
}

function* createOrUpdateResponse({ payload }) {
  try {
    const { id, ...restBody } = payload;
    if (!id) {
      // Create one
      const response = yield SuperFetch.post('/responses', payload);
      return yield put(responseActions.createResponse(response));
    }

    // Update existed one
    const response = yield SuperFetch.put(`/responses/${payload.id}`, restBody);
    return yield put(responseActions.updateResponse(response));
  } catch (err) {
    yield put({
      type: responseActions.RESPONSE_ERROR,
      payload: err.message,
    });
  }
}

function* deleteResponse({ payload }) {
  try {
    const response = yield SuperFetch.delete(`/responses/${payload.id}`);
    return yield put(responseActions.deleteResponse(response));
  } catch (err) {
    yield put({
      type: responseActions.RESPONSE_ERROR,
      payload: err.message,
    });
  }
}

export default function* responseSaga() {
  yield all([
    takeEvery(responseActions.GET_RESPONSES, getResponses),
    takeEvery(responseActions.CREATE_OR_UPDATE_RESPONSE_WATCHER, createOrUpdateResponse),
    takeEvery(responseActions.DELETE_RESPONSE_WATCHER, deleteResponse),
  ]);
}
