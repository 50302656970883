import { nonAccentVietnamese } from '@iso/lib/helpers/utility';

import companyGroupActions from './actions';

const initialState = {
  companyGroups: [],
  companyGroup: {},
  isFetched: false,
  errorMessage: '',
  status: '',
};

export default function companyGroupReducer(state = initialState, action) {
  switch (action.type) {
    case companyGroupActions.SET_COMPANY_GROUPS:
      return {
        ...state,
        companyGroups: action.payload.map(item => {
          return {
            ...item,
          };
        }),
        isFetched: true,
      };
    case companyGroupActions.CREATE_COMPANY_GROUP:
      return {
        ...state,
        companyGroups:[
          {
            ...action.payload,
          },
          ...state.companyGroups,
        ],
        status: 'CREATED',
        companyGroup: {},
      };
    case companyGroupActions.UPDATE_COMPANY_GROUP:
      return {
        ...state,
        companyGroups: [
          ...state.companyGroups.map(companyGroup => {
            return companyGroup.id === action.payload.id ? {
              ...companyGroup, 
              ...action.payload,
            } : companyGroup;
          })
        ],
        status: 'UPDATED',
      };
    case companyGroupActions.DELETE_COMPANY_GROUP:
      return {
        ...state,
        companyGroups: state.companyGroups.filter(companyGroup => companyGroup.id !== action.payload.id),
        status: 'DELETED',
      };
    case companyGroupActions.COMPANY_GROUP_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        isFetched: true,
      };

    case companyGroupActions.RESET_COMPANY_GROUP_STATUS:
      return {
        ...state,
        errorMessage: '',
        status: '',
      };
    default:
      return state;
  }
}
