const globalActions = {
  // SAGA
  GET_DROPDOWN_QUESTIONS: 'GET_DROPDOWN_QUESTIONS',
  GET_DROPDOWN_SURVEYS: 'GET_DROPDOWN_SURVEYS',
  GET_DROPDOWN_COMPANY_GROUPS: 'GET_DROPDOWN_COMPANY_GROUPS',
  GET_DROPDOWN_COMPANIES: 'GET_DROPDOWN_COMPANIES',
  GET_DROPDOWN_CAMPAIGNS: 'GET_DROPDOWN_CAMPAIGNS',
  GET_DROPDOWN_USERS: 'GET_DROPDOWN_USERS',

  // REDUCER
  SET_DROPDOWN_QUESTIONS: 'SET_DROPDOWN_QUESTIONS',
  SET_DROPDOWN_SURVEYS: 'SET_DROPDOWN_SURVEYS',
  SET_DROPDOWN_COMPANY_GROUPS: 'SET_DROPDOWN_COMPANY_GROUPS',
  SET_DROPDOWN_COMPANIES: 'SET_DROPDOWN_COMPANIES',
  SET_DROPDOWN_CAMPAIGNS: 'SET_DROPDOWN_CAMPAIGNS',
  SET_DROPDOWN_USERS: 'SET_DROPDOWN_USERS',

  // SEARCH
  GLOBAL_ERROR: 'GLOBAL_ERROR',

  // Saga functions
  getDropdownQuestions: items => ({
    type: globalActions.GET_DROPDOWN_QUESTIONS,
    payload: items,
  }),

  getDropdownSurveys: items => ({
    type: globalActions.GET_DROPDOWN_SURVEYS,
    payload: items,
  }),

  getDropdownCompanyGroups: items => ({
    type: globalActions.GET_DROPDOWN_COMPANY_GROUPS,
    payload: items,
  }),

  getDropdownCompanies: items => ({
    type: globalActions.GET_DROPDOWN_COMPANIES,
    payload: items,
  }),

  getDropdownCampaigns: items => ({
    type: globalActions.GET_DROPDOWN_CAMPAIGNS,
    payload: items,
  }),

  getDropdownUsers: items => ({
    type: globalActions.GET_DROPDOWN_USERS,
    payload: items,
  }),

  // Reducer functions
  setDropdownQuestions: items => ({
    type: globalActions.SET_DROPDOWN_QUESTIONS,
    payload: items,
  }),
  setDropdownSurveys: items => ({
    type: globalActions.SET_DROPDOWN_SURVEYS,
    payload: items,
  }),
  setDropdownCompanyGroups: items => ({
    type: globalActions.SET_DROPDOWN_COMPANY_GROUPS,
    payload: items,
  }),
  setDropdownCompanies: items => ({
    type: globalActions.SET_DROPDOWN_COMPANIES,
    payload: items,
  }),
  setDropdownCampaigns: items => ({
    type: globalActions.SET_DROPDOWN_CAMPAIGNS,
    payload: items,
  }),
  setDropdownUsers: items => ({
    type: globalActions.SET_DROPDOWN_USERS,
    payload: items,
  }),
};

export default globalActions;
