import ticketSurveyActions from './actions';

const initialState = {
  ticketSurveys: [],
  users: [],
  total: 0,
  ticketSurvey: {},
  isFetched: false,
  errorMessage: '',
  status: '',
};

export default function ticketSurveyReducer(state = initialState, action) {
  switch (action.type) {
    case ticketSurveyActions.SET_TICKET_SURVEYS:
      const { ticketSurveys, total } = action.payload;

      return {
        ...state,
        // ticketSurveys: ticketSurveys.map(item => {
        //   return {
        //     ...item,
        //     keyword: `${nonAccentVietnamese(item.ticketSubject)}`,
        //   };
        // }),
        ticketSurveys,
        total,
        isFetched: true,
      };
    case ticketSurveyActions.SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case ticketSurveyActions.TICKET_SURVEY_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        isFetched: true,
      };
    default:
      return state;
  }
}
