import { all } from 'redux-saga/effects';
import authSaga from '@iso/redux/auth/saga';

import companiesSagas from '@iso/redux/company/saga';
import contactsSagas from '@iso/redux/contact/saga';
import ticketsSagas from '@iso/redux/ticket/saga';
import userSaga from '@iso/redux/user/saga';

import ticketSurveysSagas from '@iso/redux/ticketSurvey/saga';
import templatesSagas from '@iso/redux/template/saga';
import companyGroupsSaga from '@iso/redux/companyGroup/saga';
import questionsSaga from '@iso/redux/question/saga';
import globalSaga from '@iso/redux/global/saga';
import campaignSaga from '@iso/redux/campaign/saga';
import responseSaga from '@iso/redux/response/saga';
import surveysSaga from '@iso/redux/survey/saga';
import surveyReportSaga from '@iso/redux/surveyReport/saga';
import pointReportSaga from '@iso/redux/pointReport/saga';

import clientSurveySagas from '@iso/redux/clientSurvey/saga';

export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    companiesSagas(),
    contactsSagas(),
    ticketsSagas(),
    userSaga(),
    ticketSurveysSagas(),
    templatesSagas(),
    companyGroupsSaga(),
    questionsSaga(),
    surveysSaga(),
    globalSaga(),
    campaignSaga(),
    responseSaga(),
    surveyReportSaga(),
    pointReportSaga(),

    clientSurveySagas(),
  ]);
}
