const companyActions = {
  // SAGA
  GET_COMPANIES: 'GET_COMPANIES',
  GET_COMPANY: 'GET_COMPANY',
  CREATE_OR_UPDATE_COMPANY_WATCHER: 'CREATE_OR_UPDATE_COMPANY_WATCHER',

  GET_COMPANY_CONTACTS: 'GET_COMPANY_CONTACTS',
  CREATE_OR_UPDATE_COMPANY_CONTACT_WATCHER: 'CREATE_OR_UPDATE_COMPANY_CONTACT_WATCHER',
  DELETE_CONTACT_WATCHER: 'DELETE_CONTACT_WATCHER',

  // REDUCER
  SET_COMPANIES: 'SET_COMPANIES',
  SET_COMPANY: 'SET_COMPANY',

  SET_USERS: 'SET_USERS',

  CREATE_COMPANY: 'CREATE_COMPANY',
  UPDATE_COMPANY: 'UPDATE_COMPANY',

  SET_COMPANY_CONTACTS: 'SET_COMPANY_CONTACTS',
  CREATE_CONTACT: 'CREATE_CONTACT',
  UPDATE_CONTACT: 'UPDATE_CONTACT',
  DELETE_CONTACT: 'DELETE_CONTACT',

  // SEARCH
  COMPANY_ERROR: 'COMPANY_ERROR',
  RESET_COMPANY_STATUS: 'RESET_COMPANY_STATUS',

  // Saga functions
  getCompanies: companies => ({
    type: companyActions.GET_COMPANIES,
    payload: companies,
  }),

  getCompany: companies => ({
    type: companyActions.GET_COMPANY,
    payload: companies,
  }),
  
  createOrUpdateCompanyWatcher: company => {
    return {
      type: companyActions.CREATE_OR_UPDATE_COMPANY_WATCHER,
      payload: company,
    };
  },

  // * Contacts
  getContacts: company => ({
    type: companyActions.GET_COMPANY_CONTACTS,
    payload: company,
  }),

  createOrUpdateCompanyContactWatcher: contact => {
    return {
      type: companyActions.CREATE_OR_UPDATE_COMPANY_CONTACT_WATCHER,
      payload: contact,
    };
  },

  deleteContactWatcher: contact => {
    return {
      type: companyActions.DELETE_CONTACT_WATCHER,
      payload: contact,
    };
  },

  // Reducer functions
  setCompanies: companies => ({
    type: companyActions.SET_COMPANIES,
    payload: companies,
  }),

  setUsers: companies => ({
    type: companyActions.SET_USERS,
    payload: companies,
  }),

  setCompany: company => ({
    type: companyActions.SET_COMPANY,
    payload: company,
  }),

  createCompany: company => {
    return {
      type: companyActions.CREATE_COMPANY,
      payload: company,
    };
  },
  updateCompany: company => {
    return {
      type: companyActions.UPDATE_COMPANY,
      payload: company,
    };
  },
  resetCompanyStatus: () => {
    return {
      type: companyActions.RESET_COMPANY_STATUS,
    };
  },

  // * Contacts
  createContact: contact => {
    return {
      type: companyActions.CREATE_CONTACT,
      payload: contact,
    };
  },
  updateContact: contact => {
    return {
      type: companyActions.UPDATE_CONTACT,
      payload: contact,
    };
  },
  deleteContact: contact => {
    return {
      type: companyActions.DELETE_CONTACT,
      payload: contact,
    };
  },

  // * Contacts
  setContacts: contacts => ({
    type: companyActions.SET_COMPANY_CONTACTS,
    payload: contacts,
  }),
};

export default companyActions;
