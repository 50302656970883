  import jwtConfig from '@iso/config/jwt.config';

const customHeader = (isFormData) => {
  const defaultHeader = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('id_token') || undefined,
  }

  return isFormData ? defaultHeader : {
    ...defaultHeader,
    'Content-Type': 'application/json',
  }
};

const base = async (method, url, data = {}, isFormData = false) => {
  try {
    console.log({
      method,
      url,
      data,
      isFormData,
    })

    const bodyCustom = !['get', 'delete'].includes(method) ? {
      body: isFormData ? data : JSON.stringify(data)
    } : {};

    let apiUrl = `${jwtConfig.fetchUrl}${url}`;

    if (method === 'get' && Object.keys(data).length) {
      apiUrl = `${apiUrl}?` + new URLSearchParams(data);
    }

    const res = await fetch(apiUrl, {
      method,
      headers: customHeader(isFormData),
      ...bodyCustom,
    });

    const result = await res.json();

    if (!result.id && result.code) {
      throw new Error(result.message);
    }

    return result;
  } catch (err) {
    throw new Error(err.message || 'Lỗi hệ thống');
  }
};

const downloadFile = async (method, url, data = {}, headerOptions = {}) => {
  try {
    let apiUrl = `${jwtConfig.fetchUrl}${url}`;
    let body;

    if (method === 'get' && Object.keys(data).length) {
      apiUrl = `${apiUrl}?` + new URLSearchParams(data);
    }

    if (method !== 'get' && data) {
      body = JSON.stringify(data);
    }

    const res = await fetch(apiUrl, {
      method,
      body,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('id_token') || undefined,
        ...headerOptions,
      },
    });

    return await res.blob();
  } catch (err) {
    throw new Error(err.message || 'Lỗi hệ thống');
  }
};

const SuperFetch = {};
['get', 'post', 'put', 'delete'].forEach(method => {
  SuperFetch[method] = base.bind(null, method);
});
export default SuperFetch;
export {
  downloadFile,
};