import globalActions from './actions';

const initialState = {
  dropdownQuestions: [],
  dropdownSurveys: [],
  dropdownCompanyGroups: [],
  dropdownCompanies: [],
  dropdownCampaigns: [],
  dropdownUsers: [],

  errorMessage: '',
  status: '',
};

export default function globalReducer(state = initialState, action) {
  switch (action.type) {
    case globalActions.SET_DROPDOWN_QUESTIONS:
      return {
        ...state,
        dropdownQuestions: (action.payload || []).map(item => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
      };

    case globalActions.SET_DROPDOWN_SURVEYS:
      return {
        ...state,
        dropdownSurveys: (action.payload || []).map(item => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
      };
    case globalActions.SET_DROPDOWN_COMPANY_GROUPS:
      return {
        ...state,
        dropdownCompanyGroups: (action.payload || []).map(item => ({
          ...item,
          label: item.name,
          value: item.id,
          color: item.color,
        })),
      };
    case globalActions.SET_DROPDOWN_COMPANIES:
      return {
        ...state,
        dropdownCompanies: (action.payload || []).map(item => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
      };
    case globalActions.SET_DROPDOWN_CAMPAIGNS:
      return {
        ...state,
        dropdownCampaigns: (action.payload || []).map(item => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
      };

    case globalActions.SET_DROPDOWN_USERS:
      return {
        ...state,
        dropdownUsers: (action.payload || []).map(item => ({
          ...item,
          label: item.displayName,
          value: item.id,
        })),
      };
    default:
      return state;
  }
}
