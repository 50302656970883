// saga.js
import { all, takeEvery, put, select } from 'redux-saga/effects';
import SuperFetch from '@iso/lib/helpers/superFetch';

import userActions from './actions';

const selectUsers = state => state.user;

const DEFAULT_QUERY = {
  limit: 0,
  skip: 0,
  populate: [],
  sort: '_id',
  direction: 'desc',
};


function* getUsers() {
  try {
    const users = yield SuperFetch.get('/users', {
      ...DEFAULT_QUERY,
      filter: 'isAll',
      isAll: true,
    });

    yield put(userActions.setUsers(users)); 
  } catch (err) {
    yield put({
      type: userActions.USER_ERROR,
      payload: err.message,
    }); 
  }
}

function* getUser({ payload: userId }) {
  try {
    const { users: existedUsers, isFetched } = yield select(selectUsers);
    const users = !isFetched ? yield SuperFetch.get('/users', DEFAULT_QUERY) : existedUsers;

    const foundUser = users.find(user => user.id === userId);
  
    yield all([
      yield put(userActions.setUser(foundUser)),
      yield put(userActions.setUsers(users)),
    ]);
  } catch (err) {
    yield put({
      type: userActions.USER_ERROR,
      payload: err.message,
    });
  }
}


function* createOrUpdateUser({ payload }) {
  try {
    const { id, ...restBody } = payload;
    if (!id) {
      // Create one
      const user = yield SuperFetch.post('/users', payload);
      return yield put(userActions.createUser(user));
    }

    // Update existed one
    const user = yield SuperFetch.put(`/users/${payload.id}`, restBody);
    return yield put(userActions.updateUser(user));
  } catch (err) {
    yield put({
      type: userActions.USER_ERROR,
      payload: err.message,
    });
  }
}

export default function* userSaga() {
  yield all([
    takeEvery(userActions.GET_USERS, getUsers),
    takeEvery(userActions.GET_USER, getUser),
    
    takeEvery(userActions.CREATE_OR_UPDATE_USER_WATCHER, createOrUpdateUser),
  ]);
}
