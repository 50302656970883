// saga.js
import { all, takeEvery, put } from 'redux-saga/effects';
import SuperFetch from '@iso/lib/helpers/superFetch';

import pointReportActions from './actions';

function* getPointReportCompany(action) {
  try {
    const payload = yield SuperFetch.post('/company-points/reports', {
      type: 'Company',
      ...action.payload,
    });

    yield put(pointReportActions.setPointReportCompany(payload));
  } catch (err) {
    console.log('@err', err);
  }
}

function* getPointReportUser(action) {
  try {
    const payload = yield SuperFetch.post('/company-points/reports', {
      type: 'User',
      ...action.payload,
    });

    yield put(pointReportActions.setPointReportUser(payload));
  } catch (err) {
    console.log('@err', err);
  }
}

function* getPointReportDepartment(action) {
  try {
    const payload = yield SuperFetch.post('/company-points/reports', {
      type: 'Department',
      ...action.payload,
    });

    yield put(pointReportActions.setPointReportDepartment(payload));
  } catch (err) {
    console.log('@err', err);
  }
}

export default function* pointSaga() {
  yield all([
    takeEvery(pointReportActions.GET_POINT_REPORT_COMPANY, getPointReportCompany),
    takeEvery(pointReportActions.GET_POINT_REPORT_USER, getPointReportUser),
    takeEvery(pointReportActions.GET_POINT_REPORT_DEPARTMENT, getPointReportDepartment),
  ]);
}
