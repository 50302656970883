const contactActions = {
  // SAGA
  GET_CONTACTS: 'GET_CONTACTS',
  LOAD_MORE_CONTACTS: 'LOAD_MORE_CONTACTS',
  CREATE_OR_UPDATE_CONTACT_WATCHER: 'CREATE_OR_UPDATE_CONTACT_WATCHER',

  GET_CONTACT_COMPANIES: 'GET_CONTACT_COMPANIES',

  // REDUCER
  SET_CONTACT_COMPANIES: 'SET_CONTACT_COMPANIES',

  SET_CONTACTS: 'SET_CONTACTS',
  SET_LOAD_MORE_CONTACTS: 'SET_LOAD_MORE_CONTACTS',
  CREATE_CONTACT: 'CREATE_CONTACT',
  UPDATE_CONTACT: 'UPDATE_CONTACT',

  // SEARCH
  CONTACT_ERROR: 'CONTACT_ERROR',
  RESET_CONTACT_STATUS: 'RESET_CONTACT_STATUS',

  // Saga functions
  getContacts: contact => ({
    type: contactActions.GET_CONTACTS,
    payload: contact,
  }),
  loadMoreContacts: contact => ({
    type: contactActions.LOAD_MORE_CONTACTS,
    payload: contact,
  }),

  createOrUpdateContactWatcher: contact => {
    return {
      type: contactActions.CREATE_OR_UPDATE_CONTACT_WATCHER,
      payload: contact,
    };
  },

  // Reducer functions
  setContacts: contacts => ({
    type: contactActions.SET_CONTACTS,
    payload: contacts,
  }),

  setLoadMoreContacts: contacts => ({
    type: contactActions.SET_LOAD_MORE_CONTACTS,
    payload: contacts,
  }),

  setContactCompanies: companies => ({
    type: contactActions.SET_CONTACT_COMPANIES,
    payload: companies,
  }),

  createContact: contact => {
    return {
      type: contactActions.CREATE_CONTACT,
      payload: contact,
    };
  },
  updateContact: contact => {
    return {
      type: contactActions.UPDATE_CONTACT,
      payload: contact,
    };
  },
  resetContactStatus: () => {
    return {
      type: contactActions.RESET_CONTACT_STATUS,
    };
  },
};

export default contactActions;
