const questionActions = {
  // SAGA
  GET_QUESTIONS: 'GET_QUESTIONS',
  GET_QUESTION: 'GET_QUESTION',
  CREATE_OR_UPDATE_QUESTION_WATCHER: 'CREATE_OR_UPDATE_QUESTION_WATCHER',
  DELETE_QUESTION_WATCHER: 'DELETE_QUESTION_WATCHER',

  // REDUCER
  SET_QUESTIONS: 'SET_QUESTIONS',
  SET_QUESTION: 'SET_QUESTION',

  CREATE_QUESTION: 'CREATE_QUESTION',
  UPDATE_QUESTION: 'UPDATE_QUESTION',
  DELETE_QUESTION: 'DELETE_QUESTION',

  // SEARCH
  QUESTION_ERROR: 'QUESTION_ERROR',
  RESET_QUESTION_STATUS: 'RESET_QUESTION_STATUS',


  // Saga functions
  getQuestions: (query) => ({
    type: questionActions.GET_QUESTIONS,
    payload: query,
  }),

  getQuestion: questions => ({
    type: questionActions.GET_QUESTION,
    payload: questions,
  }),
  
  createOrUpdateQuestionWatcher: question => {
    return {
      type: questionActions.CREATE_OR_UPDATE_QUESTION_WATCHER,
      payload: question,
    };
  },

  deleteQuestionWatcher: question => {
    return {
      type: questionActions.DELETE_QUESTION_WATCHER,
      payload: question,
    };
  },

  // Reducer functions
  setQuestions: questions => ({
    type: questionActions.SET_QUESTIONS,
    payload: questions,
  }),

  setQuestion: question => ({
    type: questionActions.SET_QUESTION,
    payload: question,
  }),

  createQuestion: question => {
    return {
      type: questionActions.CREATE_QUESTION,
      payload: question,
    };
  },
  updateQuestion: question => {
    return {
      type: questionActions.UPDATE_QUESTION,
      payload: question,
    };
  },
  deleteQuestion: question => {
    return {
      type: questionActions.DELETE_QUESTION,
      payload: question,
    };
  },
  resetQuestionStatus: () => {
    return {
      type: questionActions.RESET_QUESTION_STATUS,
    };
  },
};

export default questionActions;
