const ticketActions = {
  // SAGA
  GET_TICKETS: 'GET_TICKETS',
  LOAD_MORE_TICKETS: 'LOAD_MORE_TICKETS',
  UPDATE_TICKET_WATCHER: 'UPDATE_TICKET_WATCHER',
  DELETE_TICKET_WATCHER: 'DELETE_TICKET_WATCHER',

  GET_TICKET: 'GET_TICKET',
  GET_CONVERSATIONS: 'GET_CONVERSATIONS',
  CREATE_OR_REPLY_TICKET_WATCHER: 'CREATE_OR_REPLY_TICKET_WATCHER',

  // REDUCER
  SET_TICKETS: 'SET_TICKETS',
  SET_LOAD_MORE_TICKETS: 'SET_LOAD_MORE_TICKETS',
  CREATE_TICKET: 'CREATE_TICKET',
  UPDATE_TICKET: 'UPDATE_TICKET',
  DELETE_TICKET: 'DELETE_TICKET',
  
  SET_USERS: 'SET_USERS',
  SET_TICKET: 'SET_TICKET',
  SET_CONVERSATIONS: 'SET_CONVERSATIONS',

  REPLY_TICKET: 'REPLY_TICKET',

  // SEARCH
  TICKET_ERROR: 'TICKET_ERROR',
  RESET_TICKET_STATUS: 'RESET_TICKET_STATUS',

  // Saga functions
  getTickets: payload => ({
    payload,
    type: ticketActions.GET_TICKETS,
  }),
  loadMoreTickets: ticket => ({
    type: ticketActions.LOAD_MORE_TICKETS,
    payload: ticket,
  }),

  getTicket: company => ({
    type: ticketActions.GET_TICKET,
    payload: company,
  }),

  updateTicketWatcher: ticket => {
    return {
      type: ticketActions.UPDATE_TICKET_WATCHER,
      payload: ticket,
    };
  },

  createOrReplyTicketWatcher: ticket => {
    return {
      type: ticketActions.CREATE_OR_REPLY_TICKET_WATCHER,
      payload: ticket,
    };
  },

  deleteTicketWatcher: template => {
    return {
      type: ticketActions.DELETE_TICKET_WATCHER,
      payload: template,
    };
  },

  // Reducer functions
  setTickets: tickets => ({
    type: ticketActions.SET_TICKETS,
    payload: tickets,
  }),

  setLoadMoreTickets: payload => ({
    payload,
    type: ticketActions.SET_LOAD_MORE_TICKETS,
  }),

  setUsers: users => ({
    type: ticketActions.SET_USERS,
    payload: users,
  }),

  setTicket: ticket => ({
    type: ticketActions.SET_TICKET,
    payload: ticket,
  }),

  setConversations: conversations => ({
    type: ticketActions.SET_CONVERSATIONS,
    payload: conversations,
  }),

  createTicket: ticket => {
    return {
      type: ticketActions.CREATE_TICKET,
      payload: ticket,
    };
  },
  updateTicket: ticket => {
    return {
      type: ticketActions.UPDATE_TICKET,
      payload: ticket,
    };
  },
  deleteTicket: ticket => {
    return {
      type: ticketActions.DELETE_TICKET,
      payload: ticket,
    };
  },
  replyTicket: conversation => {
    return {
      type: ticketActions.REPLY_TICKET,
      payload: conversation,
    };
  },
  resetTicketStatus: () => {
    return {
      type: ticketActions.RESET_TICKET_STATUS,
    };
  },
};

export default ticketActions;
