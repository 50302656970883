const pointReportActions = {
  // SAGA
  GET_POINT_REPORT_COMPANY: 'GET_POINT_REPORT_COMPANY',
  GET_POINT_REPORT_USER: 'GET_POINT_REPORT_USER',
  GET_POINT_REPORT_DEPARTMENT: 'GET_POINT_REPORT_DEPARTMENT',
  

  // REDUCER
  SET_POINT_REPORT_COMPANY: 'SET_POINT_REPORT_COMPANY',
  SET_POINT_REPORT_USER: 'SET_POINT_REPORT_USER',
  SET_POINT_REPORT_DEPARTMENT: 'SET_POINT_REPORT_DEPARTMENT',

  SET_POINT_REPORT_FILTER_COMPANY: 'SET_POINT_REPORT_FILTER_COMPANY',
  SET_POINT_REPORT_FILTER_USER: 'SET_POINT_REPORT_FILTER_USER',
  SET_POINT_REPORT_FILTER_DEPARTMENT: 'SET_POINT_REPORT_FILTER_DEPARTMENT',


  RESET_POINT_REPORT_COMPANY: 'RESET_POINT_REPORT_COMPANY',
  RESET_POINT_REPORT_USER: 'RESET_POINT_REPORT_USER',
  RESET_POINT_REPORT_DEPARTMENT: 'RESET_POINT_REPORT_DEPARTMENT',


  // Saga functions
  getPointReportCompany: (payload) => ({
    type: pointReportActions.GET_POINT_REPORT_COMPANY,
    payload,
  }),

  getPointReportUser: (payload) => ({
    type: pointReportActions.GET_POINT_REPORT_USER,
    payload,
  }),
  getPointReportDepartment: (payload) => ({
    type: pointReportActions.GET_POINT_REPORT_DEPARTMENT,
    payload,
  }),

  // Reducer functions
  setPointReportCompany: payload => ({
    type: pointReportActions.SET_POINT_REPORT_COMPANY,
    payload,
  }),
  setPointReportUser: payload => ({
    type: pointReportActions.SET_POINT_REPORT_USER,
    payload,
  }),
  setPointReportDepartment: payload => ({
    type: pointReportActions.SET_POINT_REPORT_DEPARTMENT,
    payload,
  }),

  setPointReportFilterCompany: payload => ({
    type: pointReportActions.SET_POINT_REPORT_FILTER_COMPANY,
    payload,
  }),
  setPointReportFilterUser: payload => ({
    type: pointReportActions.SET_POINT_REPORT_FILTER_USER,
    payload,
  }),
  setPointReportFilterDepartment: payload => ({
    type: pointReportActions.SET_POINT_REPORT_FILTER_DEPARTMENT,
    payload,
  }),

  resetPointReportCompany: () => {
    return {
      type: pointReportActions.RESET_POINT_REPORT_COMPANY,
    };
  },
  resetPointReportUser: () => {
    return {
      type: pointReportActions.RESET_POINT_REPORT_USER,
    };
  },
  resetPointReportDepartment: () => {
    return {
      type: pointReportActions.RESET_POINT_REPORT_DEPARTMENT,
    };
  },
};

export default pointReportActions;
