import { nonAccentVietnamese } from '@iso/lib/helpers/utility';
import * as _ from 'lodash'

import contactActions from './actions';

const initialState = {
  contacts: [],
  total: 0,
  isFetched: false,
  errorMessage: '',
  status: '',
  users: [],
  companies: [],
  loading: false,
};

export default function contactReducer(state = initialState, action) {
  switch (action.type) {
    case contactActions.GET_CONTACTS:
      return {
        ...state,
        loading: true,
      };
    case contactActions.SET_CONTACTS:
      const { contacts, total } = action.payload;

      return {
        ...state,
        total,
        contacts: contacts,
        isFetched: true,
        loading: false,
      };
    case contactActions.LOAD_MORE_CONTACTS:
      return {
        ...state,
        loading: true,
      };
    case contactActions.SET_LOAD_MORE_CONTACTS:
      return {
        ...state,
        contacts: _.uniqBy([
          ...state.contacts,
          ...action.payload,
        ], 'id'),
        isFetched: true,
        loading: false,
      };

    case contactActions.CREATE_CONTACT:
      return {
        ...state,
        contacts:[
          {
            ...action.payload,
            // keyword: `${nonAccentVietnamese(action.payload.name)} ${action.payload.email}`,
          },
          ...state.contacts,
          
        ],
        status: 'CREATED',
      };
    case contactActions.UPDATE_CONTACT:
      return {
        ...state,
        contacts: [
          ...state.contacts.map(contact => {
            return contact.id === action.payload.id ? {
              ...contact, 
              ...action.payload,
              // keyword: `${nonAccentVietnamese(action.payload.name)} ${action.payload.email}`,
            } : contact;
          })
        ],
        status: 'UPDATED',
      };

    case contactActions.SET_CONTACT_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };

    case contactActions.CONTACT_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        isFetched: true,
        loading: false,
      };

    case contactActions.RESET_CONTACT_STATUS:
      return {
        ...state,
        errorMessage: '',
        status: '',
      };

    default:
      return state;
  }
}
