// saga.js
import { all, takeEvery, put, select } from 'redux-saga/effects';
import SuperFetch from '@iso/lib/helpers/superFetch';

import contactActions from './actions';

const selectContacts = state => state.contact;

const DEFAULT_QUERY = {
  limit: 20,
  skip: 0,
  populate: [],
  sort: '_id',
  direction: 'desc',
};

// * Company Contacts
function* getContacts({ payload }) {
  try {
    // const [contacts, companies] = yield all([
    //   SuperFetch.get('/contacts', DEFAULT_QUERY),
    //   SuperFetch.get('/companies', DEFAULT_QUERY)
    // ]);
    const { companies } = yield select(selectContacts);
    const query = {
      ...DEFAULT_QUERY,
      ...payload,
    };

    // const [contacts] = SuperFetch.get('/contacts', query);
    const [contacts, total] = yield all([
      SuperFetch.get('/contacts', query),
      SuperFetch.get('/contacts/count', query)
    ]);

    yield put(contactActions.setContacts({
      contacts,
      total: total.count,
    }));

    if (!companies.length) {
      const companies = yield SuperFetch.get('/contacts/companies');

      yield put(contactActions.setContactCompanies(companies));
    }

    // yield all([
    //   put(contactActions.setContacts(contacts)),
    //   put(contactActions.setCompanies(companies)),
    // ]);
  } catch (err) {
    yield put({
      type: contactActions.CONTACT_ERROR,
      payload: err.message,
    }); 
  }
}

function* loadMoreContacts({ payload }) {
  try {
    const query = {
      ...DEFAULT_QUERY,
      ...payload,
    };

    const contacts = yield SuperFetch.get('/contacts', query);

    yield put(contactActions.setLoadMoreContacts(contacts));

  } catch (err) {
    yield put({
      type: contactActions.CONTACT_ERROR,
      payload: err.message,
    }); 
  }
}

function* createOrUpdateContact({ payload }) {
  try {
    const { id, ...restBody } = payload;
    if (!id) {
      // Create one
      const contact = yield SuperFetch.post('/contacts', payload);
      return yield put(contactActions.createContact(contact));
    }

    // Update existed one
    const contact = yield SuperFetch.put(`/contacts/${payload.id}`, restBody);
    return yield put(contactActions.updateContact(contact));
  } catch (err) {
    yield put({
      type: contactActions.CONTACT_ERROR,
      payload: err.message,
    });
  }
}


export default function* contactSaga() {
  yield all([
    // * Contacts
    takeEvery(contactActions.GET_CONTACTS, getContacts),
    takeEvery(contactActions.LOAD_MORE_CONTACTS, loadMoreContacts),
    takeEvery(contactActions.CREATE_OR_UPDATE_CONTACT_WATCHER, createOrUpdateContact),
  ]);
}
