const ticketSurveyActions = {
  // SAGA
  GET_TICKET_SURVEYS: 'GET_TICKET_SURVEYS',
  GET_TICKET_SURVEY: 'GET_TICKET_SURVEY',

  // REDUCER
  SET_TICKET_SURVEYS: 'SET_TICKET_SURVEYS',
  SET_TICKET_SURVEY: 'SET_TICKET_SURVEY',

  SET_USERS: 'SET_USERS',

  // SEARCH
  TICKET_SURVEY_ERROR: 'TICKET_SURVEY_ERROR',


  // Saga functions
  getTicketSurveys: ticketSurveys => ({
    type: ticketSurveyActions.GET_TICKET_SURVEYS,
    payload: ticketSurveys,
  }),

  getTicketSurvey: ticketSurveys => ({
    type: ticketSurveyActions.GET_TICKET_SURVEY,
    payload: ticketSurveys,
  }),
  
  // Reducer functions
  setTicketSurveys: ticketSurveys => ({
    type: ticketSurveyActions.SET_TICKET_SURVEYS,
    payload: ticketSurveys,
  }),

  setTicketSurvey: ticketSurvey => ({
    type: ticketSurveyActions.SET_TICKET_SURVEY,
    payload: ticketSurvey,
  }),

  setUsers: users => ({
    type: ticketSurveyActions.SET_USERS,
    payload: users,
  }),
};

export default ticketSurveyActions;
