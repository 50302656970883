const companyGroupActions = {
  // SAGA
  GET_COMPANY_GROUPS: 'GET_COMPANY_GROUPS',
  GET_COMPANY_GROUP: 'GET_COMPANY_GROUP',
  CREATE_OR_UPDATE_COMPANY_GROUP_WATCHER: 'CREATE_OR_UPDATE_COMPANY_GROUP_WATCHER',
  DELETE_COMPANY_GROUP_WATCHER: 'DELETE_COMPANY_GROUP_WATCHER',

  // REDUCER
  SET_COMPANY_GROUPS: 'SET_COMPANY_GROUPS',
  SET_COMPANY_GROUP: 'SET_COMPANY_GROUP',

  CREATE_COMPANY_GROUP: 'CREATE_COMPANY_GROUP',
  UPDATE_COMPANY_GROUP: 'UPDATE_COMPANY_GROUP',
  DELETE_COMPANY_GROUP: 'DELETE_COMPANY_GROUP',

  // SEARCH
  COMPANY_GROUP_ERROR: 'COMPANY_GROUP_ERROR',
  RESET_COMPANY_GROUP_STATUS: 'RESET_COMPANY_GROUP_STATUS',


  // Saga functions
  getCompanyGroups: (query) => ({
    type: companyGroupActions.GET_COMPANY_GROUPS,
    payload: query,
  }),

  getCompanyGroup: companyGroups => ({
    type: companyGroupActions.GET_COMPANY_GROUP,
    payload: companyGroups,
  }),
  
  createOrUpdateCompanyGroupWatcher: companyGroup => {
    return {
      type: companyGroupActions.CREATE_OR_UPDATE_COMPANY_GROUP_WATCHER,
      payload: companyGroup,
    };
  },

  deleteCompanyGroupWatcher: companyGroup => {
    return {
      type: companyGroupActions.DELETE_COMPANY_GROUP_WATCHER,
      payload: companyGroup,
    };
  },

  // Reducer functions
  setCompanyGroups: companyGroups => ({
    type: companyGroupActions.SET_COMPANY_GROUPS,
    payload: companyGroups,
  }),

  setCompanyGroup: companyGroup => ({
    type: companyGroupActions.SET_COMPANY_GROUP,
    payload: companyGroup,
  }),

  createCompanyGroup: companyGroup => {
    return {
      type: companyGroupActions.CREATE_COMPANY_GROUP,
      payload: companyGroup,
    };
  },
  updateCompanyGroup: companyGroup => {
    return {
      type: companyGroupActions.UPDATE_COMPANY_GROUP,
      payload: companyGroup,
    };
  },
  deleteCompanyGroup: companyGroup => {
    return {
      type: companyGroupActions.DELETE_COMPANY_GROUP,
      payload: companyGroup,
    };
  },
  resetCompanyGroupStatus: () => {
    return {
      type: companyGroupActions.RESET_COMPANY_GROUP_STATUS,
    };
  },
};

export default companyGroupActions;
