const templateActions = {
  // SAGA
  GET_TEMPLATES: 'GET_TEMPLATES',
  GET_TEMPLATE: 'GET_TEMPLATE',
  CREATE_OR_UPDATE_TEMPLATE_WATCHER: 'CREATE_OR_UPDATE_TEMPLATE_WATCHER',
  DELETE_TEMPLATE_WATCHER: 'DELETE_TEMPLATE_WATCHER',

  // REDUCER
  SET_TEMPLATES: 'SET_TEMPLATES',
  SET_TEMPLATE: 'SET_TEMPLATE',

  CREATE_TEMPLATE: 'CREATE_TEMPLATE',
  UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
  DELETE_TEMPLATE: 'DELETE_TEMPLATE',

  // SEARCH
  TEMPLATE_ERROR: 'TEMPLATE_ERROR',
  RESET_TEMPLATE_STATUS: 'RESET_TEMPLATE_STATUS',


  // Saga functions
  getTemplates: (query) => ({
    type: templateActions.GET_TEMPLATES,
    payload: query,
  }),

  getTemplate: templates => ({
    type: templateActions.GET_TEMPLATE,
    payload: templates,
  }),
  
  createOrUpdateTemplateWatcher: template => {
    return {
      type: templateActions.CREATE_OR_UPDATE_TEMPLATE_WATCHER,
      payload: template,
    };
  },

  deleteTemplateWatcher: template => {
    return {
      type: templateActions.DELETE_TEMPLATE_WATCHER,
      payload: template,
    };
  },

  // Reducer functions
  setTemplates: templates => ({
    type: templateActions.SET_TEMPLATES,
    payload: templates,
  }),

  setTemplate: template => ({
    type: templateActions.SET_TEMPLATE,
    payload: template,
  }),

  createTemplate: template => {
    return {
      type: templateActions.CREATE_TEMPLATE,
      payload: template,
    };
  },
  updateTemplate: template => {
    return {
      type: templateActions.UPDATE_TEMPLATE,
      payload: template,
    };
  },
  deleteTemplate: template => {
    return {
      type: templateActions.DELETE_TEMPLATE,
      payload: template,
    };
  },
  resetTemplateStatus: () => {
    return {
      type: templateActions.RESET_TEMPLATE_STATUS,
    };
  },
};

export default templateActions;
