// saga.js
import { all, takeEvery, put } from 'redux-saga/effects';
import SuperFetch from '@iso/lib/helpers/superFetch';

import clientSurveyActions from './actions';

function* getClientSurvey({ payload }) {
  try {
    const { id } = payload;
    const clientSurvey = yield SuperFetch.get(`/ticket-surveys/${id}`);

    yield put(clientSurveyActions.setClientSurvey(clientSurvey));
  } catch (err) {
    yield put({
      type: clientSurveyActions.CLIENT_SURVEY_ERROR,
      payload: err.message,
    }); 
  }
}

function* updateClientSurvey({ payload }) {
  try {
    const { id, ...restBody } = payload;

    const clientSurvey = yield SuperFetch.put(`/ticket-surveys/${payload.id}`, restBody);
    return yield put(clientSurveyActions.updateClientSurvey(clientSurvey));
  } catch (err) {
    yield put({
      type: clientSurveyActions.CLIENT_SURVEY_ERROR,
      payload: err.message,
    });
  }
}

export default function* clientSurveySaga() {
  yield all([
    takeEvery(clientSurveyActions.GET_CLIENT_SURVEY, getClientSurvey),
    takeEvery(clientSurveyActions.UPDATE_CLIENT_SURVEY_WATCHER, updateClientSurvey),
  ]);
}
