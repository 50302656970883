// saga.js
import { all, takeEvery, put } from 'redux-saga/effects';
import SuperFetch from '@iso/lib/helpers/superFetch';

import templateActions from './actions';

const DEFAULT_QUERY = {
  limit: 0,
  skip: 0,
  populate: [],
  sort: '_id',
  direction: 'desc',
};

function* getTemplates({ payload }) {
  try {
    const templates = yield SuperFetch.get('/templates', {
      ...DEFAULT_QUERY,
      ...payload,
    });

    yield put(templateActions.setTemplates(templates));
  } catch (err) {
    yield put({
      type: templateActions.TEMPLATE_ERROR,
      payload: err.message,
    }); 
  }
}

function* createOrUpdateTemplate({ payload }) {
  try {
    const { id, ...restBody } = payload;
    if (!id) {
      // Create one
      const template = yield SuperFetch.post('/templates', payload);
      return yield put(templateActions.createTemplate(template));
    }

    // Update existed one
    const template = yield SuperFetch.put(`/templates/${payload.id}`, restBody);
    return yield put(templateActions.updateTemplate(template));
  } catch (err) {
    yield put({
      type: templateActions.TEMPLATE_ERROR,
      payload: err.message,
    });
  }
}

function* deleteTemplate({ payload }) {
  try {
    const template = yield SuperFetch.delete(`/templates/${payload.id}`);
    return yield put(templateActions.deleteTemplate(template));
  } catch (err) {
    yield put({
      type: templateActions.TEMPLATE_ERROR,
      payload: err.message,
    });
  }
}

export default function* templateSaga() {
  yield all([
    takeEvery(templateActions.GET_TEMPLATES, getTemplates),
    takeEvery(templateActions.CREATE_OR_UPDATE_TEMPLATE_WATCHER, createOrUpdateTemplate),
    takeEvery(templateActions.DELETE_TEMPLATE_WATCHER, deleteTemplate),
  ]);
}
