import surveyActions from './actions';

const initialState = {
  surveys: [],
  survey: {},
  isFetched: false,
  errorMessage: '',
  status: '',
};

export default function surveyReducer(state = initialState, action) {
  switch (action.type) {
    case surveyActions.SET_SURVEYS:
      const { surveys, total } = action.payload;

      return {
        ...state,
        surveys,
        total,
        isFetched: true,
      };
    case surveyActions.CREATE_SURVEY:
      return {
        ...state,
        surveys:[
          {
            ...action.payload,
          },
          ...state.surveys,
        ],
        status: 'CREATED',
        survey: {},
      };
    case surveyActions.UPDATE_SURVEY:
      return {
        ...state,
        surveys: [
          ...state.surveys.map(survey => {
            return survey.id === action.payload.id ? {
              ...survey, 
              ...action.payload,
            } : survey;
          })
        ],
        status: 'UPDATED',
      };
    case surveyActions.DELETE_SURVEY:
      return {
        ...state,
        surveys: state.surveys.filter(survey => survey.id !== action.payload.id),
        status: 'DELETED',
      };
    case surveyActions.SURVEY_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        isFetched: true,
      };

    case surveyActions.RESET_SURVEY_STATUS:
      return {
        ...state,
        errorMessage: '',
        status: '',
      };
    default:
      return state;
  }
}
