import actions from './actions';

const initState = {
  idToken: null,
  profile: {},
  role: 'SubAgent',
  isFetched: false,
  errorMessage: '',
  status: '',
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.token,
        profile: action.profile,
        errorMessage: '',
        role: action.profile.role,
      };
    case actions.SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
        errorMessage: '',
        isFetched: true,
      };
    case actions.UPDATE_PROFILE:
      return {
        ...state,
        profile: action.payload,
        idToken: action.payload.token,
        status: 'UPDATED',
        errorMessage: '',
      };
    case actions.UPDATE_PASSWORD:
      return {
        ...state,
        status: 'UPDATED_PASSWORD',
        errorMessage: '',
      };
    case actions.PROFILE_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case actions.RESET_AUTH_STATUS:
      return {
        ...state,
        errorMessage: '',
        status: '',
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        errorMessage: action.message,
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
