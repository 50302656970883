const surveyActions = {
  // SAGA
  GET_SURVEYS: 'GET_SURVEYS',
  GET_SURVEY: 'GET_SURVEY',
  CREATE_OR_UPDATE_SURVEY_WATCHER: 'CREATE_OR_UPDATE_SURVEY_WATCHER',
  DELETE_SURVEY_WATCHER: 'DELETE_SURVEY_WATCHER',

  // REDUCER
  SET_SURVEYS: 'SET_SURVEYS',
  SET_SURVEY: 'SET_SURVEY',

  CREATE_SURVEY: 'CREATE_SURVEY',
  UPDATE_SURVEY: 'UPDATE_SURVEY',
  DELETE_SURVEY: 'DELETE_SURVEY',

  // SEARCH
  SURVEY_ERROR: 'SURVEY_ERROR',
  RESET_SURVEY_STATUS: 'RESET_SURVEY_STATUS',


  // Saga functions
  getSurveys: (query) => ({
    type: surveyActions.GET_SURVEYS,
    payload: query,
  }),

  getSurvey: surveys => ({
    type: surveyActions.GET_SURVEY,
    payload: surveys,
  }),
  
  createOrUpdateSurveyWatcher: survey => {
    return {
      type: surveyActions.CREATE_OR_UPDATE_SURVEY_WATCHER,
      payload: survey,
    };
  },

  deleteSurveyWatcher: survey => {
    return {
      type: surveyActions.DELETE_SURVEY_WATCHER,
      payload: survey,
    };
  },

  // Reducer functions
  setSurveys: surveys => ({
    type: surveyActions.SET_SURVEYS,
    payload: surveys,
  }),

  setSurvey: survey => ({
    type: surveyActions.SET_SURVEY,
    payload: survey,
  }),

  createSurvey: survey => {
    return {
      type: surveyActions.CREATE_SURVEY,
      payload: survey,
    };
  },
  updateSurvey: survey => {
    return {
      type: surveyActions.UPDATE_SURVEY,
      payload: survey,
    };
  },
  deleteSurvey: survey => {
    return {
      type: surveyActions.DELETE_SURVEY,
      payload: survey,
    };
  },
  resetSurveyStatus: () => {
    return {
      type: surveyActions.RESET_SURVEY_STATUS,
    };
  },
};

export default surveyActions;
