import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import drawer from '@iso/redux/drawer/reducer';
import modal from '@iso/redux/modal/reducer';

import user from '@iso/redux/user/reducer';
import company from '@iso/redux/company/reducer';
import contact from '@iso/redux/contact/reducer';
import ticket from '@iso/redux/ticket/reducer';
import template from '@iso/redux/template/reducer';
import ticketSurvey from '@iso/redux/ticketSurvey/reducer';
import companyGroup from '@iso/redux/companyGroup/reducer';
import question from '@iso/redux/question/reducer';
import survey from '@iso/redux/survey/reducer';
import global from '@iso/redux/global/reducer';
import campaign from '@iso/redux/campaign/reducer';
import response from '@iso/redux/response/reducer';

import clientSurvey from '@iso/redux/clientSurvey/reducer';
import surveyReport from '@iso/redux/surveyReport/reducer';
import pointReport from '@iso/redux/pointReport/reducer';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,

  modal,
  drawer,

  user,
  company,
  contact,
  ticket,

  template,
  ticketSurvey,
  companyGroup,
  question,
  survey,
  global,
  campaign,
  response,
  
  clientSurvey,
  surveyReport,
  pointReport,
});
