// saga.js
import { all, takeEvery, put } from 'redux-saga/effects';
import SuperFetch from '@iso/lib/helpers/superFetch';

import surveyReportActions from './actions';

function* getReportSurveyOverview(action) {
  try {
    const payload = yield SuperFetch.post('/auth/report/survey', {
      type: 'Overview',
      ...action.payload,
    });

    yield put(surveyReportActions.setReportSurveyOverview(payload));
  } catch (err) {
    console.log('@err', err);
  }
}

function* getReportSurveyCompany(action) {
  try {
    const payload = yield SuperFetch.post('/auth/report/survey', {
      type: 'Company',
      ...action.payload,
    });

    yield put(surveyReportActions.setReportSurveyCompany(payload.data));
  } catch (err) {
    console.log('@err', err);
  }
}

function* getReportSurveyCampaign(action) {
  try {
    const payload = yield SuperFetch.post('/auth/report/survey', {
      type: 'Campaign',
      ...action.payload,
    });

    yield put(surveyReportActions.setReportSurveyCampaign(payload));
  } catch (err) {
    console.log('@err', err);
  }
}

function* getReportSurveyCampaignUserNps(action) {
  try {
    const payload = yield SuperFetch.post('/auth/report/campaign/get-user-nps', {
      ...action.payload,
    });

    yield put(surveyReportActions.setReportSurveyCampaignUserNps(payload));
  } catch (err) {
    console.log('@err', err);
  }
}

export default function* surveySaga() {
  yield all([
    takeEvery(surveyReportActions.GET_SURVEY_REPORT_OVERVIEW, getReportSurveyOverview),
    takeEvery(surveyReportActions.GET_SURVEY_REPORT_COMPANY, getReportSurveyCompany),
    takeEvery(surveyReportActions.GET_SURVEY_REPORT_CAMPAIGN, getReportSurveyCampaign),
    takeEvery(surveyReportActions.GET_SURVEY_REPORT_CAMPAIGN_USER_NPS, getReportSurveyCampaignUserNps),
  ]);
}
