import clientSurveyActions from './actions';

const initialState = {
  clientSurvey: {},
  isFetched: false,
  errorMessage: '',
  status: '',
};

export default function clientSurveyReducer(state = initialState, action) {
  switch (action.type) {
    case clientSurveyActions.SET_CLIENT_SURVEY:
      return {
        ...state,
        clientSurvey: action.payload,
        isFetched: true,
      };
    case clientSurveyActions.UPDATE_CLIENT_SURVEY:
      return {
        ...state,
        status: 'UPDATED',
        clientSurvey: action.payload,
      };
    case clientSurveyActions.CLIENT_SURVEY_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        isFetched: true,
      };

    case clientSurveyActions.RESET_CLIENT_SURVEY_STATUS:
      return {
        ...state,
        errorMessage: '',
        status: '',
      };
    default:
      return state;
  }
}
