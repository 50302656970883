import { nonAccentVietnamese } from '@iso/lib/helpers/utility';
import * as _ from 'lodash';

import ticketActions from './actions';

const initialState = {
  tickets: [],
  isFetched: false,
  errorMessage: '',
  status: '',

  ticket: {},
  users: [],
  conversations: [],
  loading: false,
  total: 0,
};

export default function ticketReducer(state = initialState, action) {
  switch (action.type) {
    case ticketActions.SET_TICKETS: {
      const { tickets, total } = action.payload;

      return {
        ...state,
        tickets,
        total,
        isFetched: true,
        ticket: {},
        conversations: [],
      };
    }

    case ticketActions.LOAD_MORE_TICKETS:
      return {
        ...state,
        loading: true,
      };
    case ticketActions.SET_LOAD_MORE_TICKETS: {
      const { tickets, total } = action.payload;

      return {
        ...state,
        tickets: _.uniqBy([
          ...state.tickets,
          ...tickets,
        ], 'id'),
        total,
        isFetched: true,
        loading: false,
      };
    }

    case ticketActions.CREATE_TICKET:
      const { id } = action.payload;
      // * Forwarded
      if (!id) {
        return {
          ...state,
          status: 'CREATED', 
        };
      }

      return {
        ...state,
        tickets:[
          {
            ...action.payload,
          },
          ...state.tickets,
        ],
        status: 'CREATED',
      };
    case ticketActions.UPDATE_TICKET:
      return {
        ...state,
        tickets: [
          ...state.tickets.map(ticket => {
            return ticket.id === action.payload.id ? {
              ...ticket, 
              ...action.payload,
            } : ticket;
          })
        ],
        ticket: {
          ...state.ticlet,
          ...action.payload,
        },
        status: 'UPDATED',
      };
    case ticketActions.DELETE_TICKET:
      return {
        ...state,
        tickets: state.tickets.filter(ticket => ticket.id !== action.payload.id),
        status: 'DELETED',
      };

    case ticketActions.SET_USERS:
      return {
        ...state,
        users: action.payload,
      };

    case ticketActions.SET_TICKET:
      return {
        ...state,
        ticket: action.payload,
      };

    case ticketActions.SET_CONVERSATIONS:
      return {
        ...state,
        conversations: action.payload,
      };
    case ticketActions.REPLY_TICKET:
      return {
        ...state,
        conversations: [
          ...state.conversations,
          action.payload,
        ],
        status: 'CREATED',
      };

    case ticketActions.TICKET_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        isFetched: true,
      };

    case ticketActions.RESET_TICKET_STATUS:
      return {
        ...state,
        errorMessage: '',
        status: '',
      };

    default:
      return state;
  }
}
