// saga.js
import { all, takeEvery, put, select } from 'redux-saga/effects';
import SuperFetch from '@iso/lib/helpers/superFetch';

import companyActions from './actions';

const selectCompanies = state => state.company;

const DEFAULT_QUERY = {
  limit: 10,
  skip: 0,
  populate: [],
  sort: '_id',
  direction: 'desc',
};

function* getCompanies({ payload }) {
  try {
    const query = {
      ...DEFAULT_QUERY,
      ...payload,
    };

    const [
      companies,
      total,
      users
    ] = yield all([
      SuperFetch.get('/companies', query),
      SuperFetch.get('/companies/count', query),
      SuperFetch.get('/users', {
        ...DEFAULT_QUERY,
        limit: 0,
      }),
    ]);

    yield all([
      put(companyActions.setCompanies({
        companies,
        total: total.count,
      })),
      put(companyActions.setUsers(users)),
    ]);
  } catch (err) {
    yield put({
      type: companyActions.COMPANY_ERROR,
      payload: err.message,
    }); 
  }
}

function* getCompany({ payload: companyId }) {
  try {
    const foundCompany = yield SuperFetch.get(`/companies/${companyId}`);
    const users = yield SuperFetch.get('/users', {
      ...DEFAULT_QUERY,
      limit: 0,
    });

    yield all([
      put(companyActions.setUsers(users)),
      put(companyActions.setCompany(foundCompany)),
    ]);
  } catch (err) {
    yield put({
      type: companyActions.COMPANY_ERROR,
      payload: err.message,
    });
  }
}

function* createOrUpdateCompany({ payload }) {
  try {
    const { id, ...restBody } = payload;
    if (!id) {
      // Create one
      const company = yield SuperFetch.post('/companies', payload);
      return yield put(companyActions.createCompany(company));
    }

    // Update existed one
    const company = yield SuperFetch.put(`/companies/${payload.id}`, restBody);
    return yield put(companyActions.updateCompany(company));
  } catch (err) {
    yield put({
      type: companyActions.COMPANY_ERROR,
      payload: err.message,
    });
  }
}
// * Company Contacts
function* getContacts({ payload: company }) {
  if (!company || !company.id) {
    return;
  }

  try {
    const contacts = yield SuperFetch.get('/contacts', {
      ...DEFAULT_QUERY,
      filter: 'company',
      company: company.id,
    });

    yield put(companyActions.setContacts(contacts));
  } catch (err) {
    yield put({
      type: companyActions.COMPANY_ERROR,
      payload: err.message,
    }); 
  }
}

function* createOrUpdateContact({ payload }) {
  try {
    const { company } = yield select(selectCompanies);
    const { id, ...restBody } = payload;
    if (!id) {
      // Create one
      const contact = yield SuperFetch.post('/contacts', {
        ...payload,
        company: company.id,
      });
      return yield put(companyActions.createContact(contact));
    }

    // Update existed one
    const contact = yield SuperFetch.put(`/contacts/${payload.id}`, {
      ...restBody,
      company: company.id,
    });
    return yield put(companyActions.updateContact(contact));
  } catch (err) {
    yield put({
      type: companyActions.COMPANY_ERROR,
      payload: err.message,
    });
  }
}

function* deleteContact({ payload }) {
  try {
    const contact = yield SuperFetch.delete(`/contacts/${payload.id}`);
    return yield put(companyActions.deleteContact(contact));
  } catch (err) {
    yield put({
      type: companyActions.COMPANY_ERROR,
      payload: err.message,
    });
  }
}

export default function* companySaga() {
  yield all([
    takeEvery(companyActions.GET_COMPANIES, getCompanies),
    takeEvery(companyActions.GET_COMPANY, getCompany),
    takeEvery(companyActions.CREATE_OR_UPDATE_COMPANY_WATCHER, createOrUpdateCompany),

    // * Contacts
    takeEvery(companyActions.GET_COMPANY_CONTACTS, getContacts),
    takeEvery(companyActions.CREATE_OR_UPDATE_COMPANY_CONTACT_WATCHER, createOrUpdateContact),
    takeEvery(companyActions.DELETE_CONTACT_WATCHER, deleteContact),
  ]);
}
