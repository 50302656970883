const surveyReportActions = {
  // SAGA
  GET_SURVEY_REPORT_OVERVIEW: 'GET_SURVEY_REPORT_OVERVIEW',
  GET_SURVEY_REPORT_COMPANY: 'GET_SURVEY_REPORT_COMPANY',
  GET_SURVEY_REPORT_CAMPAIGN: 'GET_SURVEY_REPORT_CAMPAIGN',
  GET_SURVEY_REPORT_CAMPAIGN_USER_NPS: 'GET_SURVEY_REPORT_CAMPAIGN_USER_NPS',


  // REDUCER
  SET_SURVEY_REPORT_OVERVIEW: 'SET_SURVEY_REPORT_OVERVIEW',
  SET_SURVEY_REPORT_COMPANY: 'SET_SURVEY_REPORT_COMPANY',
  SET_SURVEY_REPORT_CAMPAIGN: 'SET_SURVEY_REPORT_CAMPAIGN',
  SET_SURVEY_REPORT_CAMPAIGN_USER_NPS: 'SET_SURVEY_REPORT_CAMPAIGN_USER_NPS',

  SET_SURVEY_REPORT_FILTER_OVERVIEW: 'SET_SURVEY_REPORT_FILTER_OVERVIEW',
  SET_SURVEY_REPORT_FILTER_COMPANY: 'SET_SURVEY_REPORT_FILTER_COMPANY',
  SET_SURVEY_REPORT_FILTER_CAMPAIGN: 'SET_SURVEY_REPORT_FILTER_CAMPAIGN',


  RESET_SURVEY_REPORT_OVERVIEW: 'RESET_SURVEY_REPORT_OVERVIEW',
  RESET_SURVEY_REPORT_COMPANY: 'RESET_SURVEY_REPORT_COMPANY',
  RESET_SURVEY_REPORT_CAMPAIGN: 'RESET_SURVEY_REPORT_CAMPAIGN',


  // Saga functions
  getReportSurveyOverview: (payload) => ({
    type: surveyReportActions.GET_SURVEY_REPORT_OVERVIEW,
    payload,
  }),

  getReportSurveyCompany: (payload) => ({
    type: surveyReportActions.GET_SURVEY_REPORT_COMPANY,
    payload,
  }),

  getReportSurveyCampaign: (payload) => ({
    type: surveyReportActions.GET_SURVEY_REPORT_CAMPAIGN,
    payload,
  }),

  getReportSurveyCampaignUserNps: (payload) => ({
    type: surveyReportActions.GET_SURVEY_REPORT_CAMPAIGN_USER_NPS,
    payload,
  }),

  // Reducer functions
  setReportSurveyOverview: payload => ({
    type: surveyReportActions.SET_SURVEY_REPORT_OVERVIEW,
    payload,
  }),
  setReportSurveyCompany: payload => ({
    type: surveyReportActions.SET_SURVEY_REPORT_COMPANY,
    payload,
  }),
  setReportSurveyCampaign: payload => ({
    type: surveyReportActions.SET_SURVEY_REPORT_CAMPAIGN,
    payload,
  }),
  setReportSurveyCampaignUserNps: payload => ({
    type: surveyReportActions.SET_SURVEY_REPORT_CAMPAIGN_USER_NPS,
    payload,
  }),

  setReportSurveyFilterOverview: payload => ({
    type: surveyReportActions.SET_SURVEY_REPORT_FILTER_OVERVIEW,
    payload,
  }),
  setReportSurveyFilterCompany: payload => ({
    type: surveyReportActions.SET_SURVEY_REPORT_FILTER_COMPANY,
    payload,
  }),
  setReportSurveyFilterCampaign: payload => ({
    type: surveyReportActions.SET_SURVEY_REPORT_FILTER_CAMPAIGN,
    payload,
  }),

  resetSurveyReportOverview: () => {
    return {
      type: surveyReportActions.RESET_SURVEY_REPORT_OVERVIEW,
    };
  },
  resetSurveyReportCompany: () => {
    return {
      type: surveyReportActions.RESET_SURVEY_REPORT_COMPANY,
    };
  },
  resetSurveyReportCampaign: () => {
    return {
      type: surveyReportActions.RESET_SURVEY_REPORT_CAMPAIGN,
    };
  },
};

export default surveyReportActions;
