import responseActions from './actions';

const initialState = {
  responses: [],
  response: {},
  isFetched: false,
  errorMessage: '',
  status: '',
};

export default function responseReducer(state = initialState, action) {
  switch (action.type) {
    case responseActions.SET_RESPONSES:
      const { responses, total } = action.payload;

      return {
        ...state,
        responses,
        total,
        isFetched: true,
      };
    case responseActions.CREATE_RESPONSE:
      return {
        ...state,
        responses:[
          {
            ...action.payload,
          },
          ...state.responses,
        ],
        status: 'CREATED',
        response: {},
      };
    case responseActions.UPDATE_RESPONSE:
      return {
        ...state,
        responses: [
          ...state.responses.map(response => {
            return response.id === action.payload.id ? {
              ...response, 
              ...action.payload,
            } : response;
          })
        ],
        status: 'UPDATED',
      };
    case responseActions.DELETE_RESPONSE:
      return {
        ...state,
        responses: state.responses.filter(response => response.id !== action.payload.id),
        status: 'DELETED',
      };
    case responseActions.RESPONSE_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        isFetched: true,
      };

    case responseActions.RESET_RESPONSE_STATUS:
      return {
        ...state,
        errorMessage: '',
        status: '',
      };
    default:
      return state;
  }
}
