import moment from 'moment';
import surveyReportActions from './actions';

const initialState = {
  surveyReportFilterOverview: {
    startMonth: moment().startOf('year').format('MM'),
    startYear: moment().startOf('year').format('YYYY'),
    endMonth: moment().endOf('year').format('MM'),
    endYear: moment().endOf('year').format('YYYY'),
    region: '',
    yearOfBirth: '',
    gender: '',
  },
  surveyReportFilterCompany: {
    startMonth: moment().startOf('year').format('MM'),
    startYear: moment().startOf('year').format('YYYY'),
    endMonth: moment().endOf('year').format('MM'),
    endYear: moment().endOf('year').format('YYYY'),
    company: '',
  },
  surveyReportFilterCampaign: {
    campaign: '',
  },

  surveyReportOverview: [],
  surveyReportCompany: [],
  surveyReportCampaign: {
    low: 0,
    medium: 0,
    high: 0,
  },
  surveyReportCampaignUserNps: [],

  errorMessage: '',
};

export default function surveyReducer(state = initialState, action) {
  switch (action.type) {
    case surveyReportActions.SET_SURVEY_REPORT_OVERVIEW:
      return {
        ...state,
        surveyReportOverview: action.payload,
      };

    case surveyReportActions.SET_SURVEY_REPORT_COMPANY:
      return {
        ...state,
        surveyReportCompany: action.payload,
      };

    case surveyReportActions.SET_SURVEY_REPORT_CAMPAIGN:
      return {
        ...state,
        surveyReportCampaign: action.payload,
      };

    case surveyReportActions.SET_SURVEY_REPORT_CAMPAIGN_USER_NPS:
      return {
        ...state,
        surveyReportCampaignUserNps: action.payload,
      };

    case surveyReportActions.SET_SURVEY_REPORT_FILTER_OVERVIEW:
      return {
        ...state,
        surveyReportFilterOverview: {
          ...state.surveyReportFilterOverview,
          ...action.payload,
        },
      };

    case surveyReportActions.SET_SURVEY_REPORT_FILTER_COMPANY:
      return {
        ...state,
        surveyReportFilterCompany: {
          ...state.surveyReportFilterCompany,
          ...action.payload,
        },
      };

    case surveyReportActions.SET_SURVEY_REPORT_FILTER_CAMPAIGN:
      return {
        ...state,
        surveyReportFilterCampaign: {
          ...state.surveyReportFilterCampaign,
          ...action.payload,
        },
      };

    case surveyReportActions.RESET_SURVEY_REPORT_OVERVIEW:
      return {
        ...state,
        surveyReportOverview: [],
        surveyReportFilterOverview: {
          startMonth: 0,
          startYear: 0,
          endMonth: 0,
          endYear: 0,
          region: '',
          yearOfBirth: '',
          gender: '',
        },
      };

    case surveyReportActions.RESET_SURVEY_REPORT_COMPANY:
      return {
        ...state,
        surveyReportCompany: [],
        surveyReportFilterCompany: {
          startMonth: 0,
          startYear: 0,
          endMonth: 0,
          endYear: 0,
          company: '',
        },
      };

    case surveyReportActions.RESET_SURVEY_REPORT_CAMPAIGN:
      return {
        ...state,
        surveyReportCampaign: {},
        surveyReportFilterCampaign: {
          campaign: '',
        },
        surveyReportCampaignUserNps: [],
      };

    default:
      return state;
  }
}
