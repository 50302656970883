import campaignActions from './actions';

const initialState = {
  campaigns: [],
  companies: [],
  campaign: {},
  campaignUsers: [],
  isFetched: false,
  errorMessage: '',
  status: '',
};

export default function campaignReducer(state = initialState, action) {
  switch (action.type) {
    case campaignActions.SET_CAMPAIGNS:
      const { campaigns, total } = action.payload;

      return {
        ...state,
        campaigns,
        total,
        isFetched: true,
      };
    case campaignActions.SET_CAMPAIGN:
      return {
        ...state,
        campaign: action.payload,
      };
    case campaignActions.SET_CAMPAIGN_USERS:
      return {
        ...state,
        campaignUsers: action.payload,
      };
    case campaignActions.SET_CAMPAIGN_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case campaignActions.CREATE_CAMPAIGN:
      return {
        ...state,
        campaigns:[
          {
            ...action.payload,
          },
          ...state.campaigns,
        ],
        status: 'UPDATED',
        campaign: {},
      };
    case campaignActions.DO_SURVEY_CAMPAIGN:
      const { company: companyId, status } = action.payload;

      return {
        ...state,
        companies: state.companies.map(item => {
          if (item.id === companyId) {
            return {
              ...item,
              responseStatus: status,
              response: {
                ...action.payload,
              },
            };
          }
          
          return item;
        }),
        status: 'UPDATED',
      };
    case campaignActions.UPDATE_CAMPAIGN:
      return {
        ...state,
        campaigns: [
          ...state.campaigns.map(campaign => {
            return campaign.id === action.payload.id ? {
              ...campaign, 
              ...action.payload,
            } : campaign;
          })
        ],
        status: 'UPDATED',
      };
    case campaignActions.DELETE_CAMPAIGN:
      return {
        ...state,
        campaigns: state.campaigns.filter(campaign => campaign.id !== action.payload.id),
        status: 'DELETED',
      };
    case campaignActions.CAMPAIGN_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        isFetched: true,
      };

    case campaignActions.RESET_CAMPAIGN_STATUS:
      return {
        ...state,
        errorMessage: '',
        status: '',
      };
    default:
      return state;
  }
}
