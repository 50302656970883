import moment from 'moment';

import pointReportActions from './actions';

const initialState = {
  pointReportFilterCompany: {
    startMonth: moment().startOf('year').format('MM'),
    startYear: moment().startOf('year').format('YYYY'),
    endMonth: moment().endOf('year').format('MM'),
    endYear: moment().endOf('year').format('YYYY'),
    company: '',
    department: '',
  },
  pointReportFilterUser: {
    startMonth: moment().startOf('year').format('MM'),
    startYear: moment().startOf('year').format('YYYY'),
    endMonth: moment().endOf('year').format('MM'),
    endYear: moment().endOf('year').format('YYYY'),
    user: '',
  },
  pointReportFilterDepartment: {
    startMonth: moment().startOf('year').format('MM'),
    startYear: moment().startOf('year').format('YYYY'),
    endMonth: moment().endOf('year').format('MM'),
    endYear: moment().endOf('year').format('YYYY'),
    department: '',
    companyService: '',
    companyStatus: '',
  },
  
  pointReportCompany: [],
  pointReportUser: {},
  pointReportDepartment: {},
  
  errorMessage: '',
};

export default function pointReducer(state = initialState, action) {
  switch (action.type) {
    case pointReportActions.SET_POINT_REPORT_COMPANY:
      return {
        ...state,
        pointReportCompany: action.payload,
      };

    case pointReportActions.SET_POINT_REPORT_USER:
      return {
        ...state,
        pointReportUser: action.payload,
      };

    case pointReportActions.SET_POINT_REPORT_DEPARTMENT:
      return {
        ...state,
        pointReportDepartment: action.payload,
      };

    case pointReportActions.SET_POINT_REPORT_FILTER_COMPANY:
      return {
        ...state,
        pointReportFilterCompany: {
          ...state.pointReportFilterCompany,
          ...action.payload,
        },
      };

    case pointReportActions.SET_POINT_REPORT_FILTER_USER:
      return {
        ...state,
        pointReportFilterUser: {
          ...state.pointReportFilterUser,
          ...action.payload,
        },
      };

    case pointReportActions.SET_POINT_REPORT_FILTER_DEPARTMENT:
      return {
        ...state,
        pointReportFilterDepartment: {
          ...state.pointReportFilterDepartment,
          ...action.payload,
        },
      };
    
    case pointReportActions.RESET_POINT_REPORT_COMPANY:
      return {
        ...state,
        pointReportCompany: [],
        pointReportFilterCompany: {
          startMonth: 0,
          startYear: 0,
          endMonth: 0,
          endYear: 0,
          company: '',
          user: '',
        },
      };

    case pointReportActions.RESET_POINT_REPORT_USER:
      return {
        ...state,
        pointReportUser: {},
        pointReportFilterUser: {
          startMonth: 0,
          startYear: 0,
          endMonth: 0,
          endYear: 0,
          user: '',
        },
      };

    case pointReportActions.RESET_POINT_REPORT_DEPARTMENT:
      return {
        ...state,
        pointReportDepartment: {},
        pointReportFilterDepartment: {
          startMonth: 0,
          startYear: 0,
          endMonth: 0,
          endYear: 0,
          department: '',
          companyService: '',
          companyStatus: '',
        },
      };
      
    default:
      return state;
  }
}
