const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  GET_PROFILE: 'GET_PROFILE',
  SET_PROFILE: 'SET_PROFILE',

  UPDATE_PROFILE_WATCHER: 'UPDATE_PROFILE_WATCHER',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  
  UPDATE_PASSWORD_WATCHER: 'UPDATE_PASSWORD_WATCHER',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  
  PROFILE_ERROR: 'PROFILE_ERROR',
  RESET_AUTH_STATUS: 'RESET_AUTH_STATUS',


  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: ({
    email,
    password
  }) => {
    return {
      type: actions.LOGIN_REQUEST,
      payload: {
        email,
        password,
      },
    };
  },
  logout: () => ({
    type: actions.LOGOUT,
  }),

  getProfile: profile => ({
    type: actions.GET_PROFILE,
    payload: profile,
  }),
  updateProfileWatcher: profile => ({
    type: actions.UPDATE_PROFILE_WATCHER,
    payload: profile,
  }),
  updatePasswordWatcher: payload => ({
    type: actions.UPDATE_PASSWORD_WATCHER,
    payload,
  }),

  // Reducer
  setProfile: profile => ({
    type: actions.SET_PROFILE,
    payload: profile,
  }),
  updateProfile: profile => ({
    type: actions.UPDATE_PROFILE,
    payload: profile,
  }),
  updatePassword: () => ({
    type: actions.UPDATE_PASSWORD,
  }),
  resetAuthStatus: () => {
    return {
      type: actions.RESET_AUTH_STATUS,
    };
  },
};
export default actions;
