// saga.js
import { all, takeEvery, put, select } from 'redux-saga/effects';
import SuperFetch from '@iso/lib/helpers/superFetch';

import globalActions from './actions';

const selectGlobal = state => state.global;

function* getDropdownQuestions({ payload }) {
  try {
    const items = yield SuperFetch.get('/questions', {
      limit: 0,
      select: 'name,options',
      isActive: true,
      filter: 'isActive',
    });

    yield put(globalActions.setDropdownQuestions(items));
  } catch (err) {
    yield put({
      type: globalActions.GLOBAL_ERROR,
      payload: err.message,
    }); 
  }
}
function* getDropdownSurveys({ payload }) {
  try {
    const items = yield SuperFetch.get('/surveys', {
      limit: 0,
      select: 'name',
      isActive: true,
      filter: 'isActive',
    });

    yield put(globalActions.setDropdownSurveys(items));
  } catch (err) {
    yield put({
      type: globalActions.GLOBAL_ERROR,
      payload: err.message,
    }); 
  }
}

function* getDropdownCompanyGroups({ payload }) {
  try {
    const items = yield SuperFetch.get('/company-groups', {
      limit: 0,
      select: 'name,color',
      isActive: true,
      filter: 'isActive',
    });

    yield put(globalActions.setDropdownCompanyGroups(items));
  } catch (err) {
    yield put({
      type: globalActions.GLOBAL_ERROR,
      payload: err.message,
    }); 
  }
}

function* getDropdownCompanies({ payload }) {
  try {
    const items = yield SuperFetch.get('/companies', {
      limit: 0,
      select: 'name',
    });

    yield put(globalActions.setDropdownCompanies(items));
  } catch (err) {
    yield put({
      type: globalActions.GLOBAL_ERROR,
      payload: err.message,
    }); 
  }
}

function* getDropdownCampaigns({ payload }) {
  try {
    const items = yield SuperFetch.get('/campaigns', {
      limit: 0,
      select: 'name',
    });

    yield put(globalActions.setDropdownCampaigns(items));
  } catch (err) {
    yield put({
      type: globalActions.GLOBAL_ERROR,
      payload: err.message,
    }); 
  }
}

function* getDropdownUsers({ payload }) {
  try {
    const items = yield SuperFetch.get('/users', {
      limit: 0,
      select: 'displayName,role',
      ...payload,
    });

    yield put(globalActions.setDropdownUsers(items));
  } catch (err) {
    yield put({
      type: globalActions.GLOBAL_ERROR,
      payload: err.message,
    }); 
  }
}


export default function* globalSaga() {
  yield all([
    takeEvery(globalActions.GET_DROPDOWN_QUESTIONS, getDropdownQuestions),
    takeEvery(globalActions.GET_DROPDOWN_SURVEYS, getDropdownSurveys),
    takeEvery(globalActions.GET_DROPDOWN_COMPANY_GROUPS, getDropdownCompanyGroups),
    takeEvery(globalActions.GET_DROPDOWN_COMPANIES, getDropdownCompanies),
    takeEvery(globalActions.GET_DROPDOWN_CAMPAIGNS, getDropdownCampaigns),
    takeEvery(globalActions.GET_DROPDOWN_USERS, getDropdownUsers),
  ]);
}
