import questionActions from './actions';

const initialState = {
  questions: [],
  question: {},
  isFetched: false,
  errorMessage: '',
  status: '',
};

export default function questionReducer(state = initialState, action) {
  switch (action.type) {
    case questionActions.SET_QUESTIONS:
      const { questions, total } = action.payload;

      return {
        ...state,
        questions,
        total,
        isFetched: true,
      };
    case questionActions.CREATE_QUESTION:
      return {
        ...state,
        questions:[
          {
            ...action.payload,
          },
          ...state.questions,
        ],
        status: 'CREATED',
        question: {},
      };
    case questionActions.UPDATE_QUESTION:
      return {
        ...state,
        questions: [
          ...state.questions.map(question => {
            return question.id === action.payload.id ? {
              ...question, 
              ...action.payload,
            } : question;
          })
        ],
        status: 'UPDATED',
      };
    case questionActions.DELETE_QUESTION:
      return {
        ...state,
        questions: state.questions.filter(question => question.id !== action.payload.id),
        status: 'DELETED',
      };
    case questionActions.QUESTION_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        isFetched: true,
      };

    case questionActions.RESET_QUESTION_STATUS:
      return {
        ...state,
        errorMessage: '',
        status: '',
      };
    default:
      return state;
  }
}
