// saga.js
import { all, takeEvery, put } from 'redux-saga/effects';
import SuperFetch from '@iso/lib/helpers/superFetch';

import questionActions from './actions';

const DEFAULT_QUERY = {
  limit: 10,
  skip: 0,
  populate: [],
  sort: '_id',
  direction: 'desc',
};

function* getQuestions({ payload }) {
  try {
    const query = {
      ...DEFAULT_QUERY,
      ...payload,
    };

    const [questions, total] = yield all([
      SuperFetch.get('/questions', query),
      SuperFetch.get('/questions/count', query),
    ]);

    yield put(questionActions.setQuestions({
      questions,
      total: total.count,
    }));
  } catch (err) {
    yield put({
      type: questionActions.QUESTION_ERROR,
      payload: err.message,
    }); 
  }
}

function* createOrUpdateQuestion({ payload }) {
  try {
    const { id, ...restBody } = payload;
    if (!id) {
      // Create one
      const question = yield SuperFetch.post('/questions', payload);
      return yield put(questionActions.createQuestion(question));
    }

    // Update existed one
    const question = yield SuperFetch.put(`/questions/${payload.id}`, restBody);
    return yield put(questionActions.updateQuestion(question));
  } catch (err) {
    yield put({
      type: questionActions.QUESTION_ERROR,
      payload: err.message,
    });
  }
}

function* deleteQuestion({ payload }) {
  try {
    const question = yield SuperFetch.delete(`/questions/${payload.id}`);
    return yield put(questionActions.deleteQuestion(question));
  } catch (err) {
    yield put({
      type: questionActions.QUESTION_ERROR,
      payload: err.message,
    });
  }
}

export default function* questionSaga() {
  yield all([
    takeEvery(questionActions.GET_QUESTIONS, getQuestions),
    takeEvery(questionActions.CREATE_OR_UPDATE_QUESTION_WATCHER, createOrUpdateQuestion),
    takeEvery(questionActions.DELETE_QUESTION_WATCHER, deleteQuestion),
  ]);
}
