const responseActions = {
  // SAGA
  GET_RESPONSES: 'GET_RESPONSES',
  GET_RESPONSE: 'GET_RESPONSE',
  CREATE_OR_UPDATE_RESPONSE_WATCHER: 'CREATE_OR_UPDATE_RESPONSE_WATCHER',
  DELETE_RESPONSE_WATCHER: 'DELETE_RESPONSE_WATCHER',

  // REDUCER
  SET_RESPONSES: 'SET_RESPONSES',
  SET_RESPONSE: 'SET_RESPONSE',

  CREATE_RESPONSE: 'CREATE_RESPONSE',
  UPDATE_RESPONSE: 'UPDATE_RESPONSE',
  DELETE_RESPONSE: 'DELETE_RESPONSE',

  // SEARCH
  RESPONSE_ERROR: 'RESPONSE_ERROR',
  RESET_RESPONSE_STATUS: 'RESET_RESPONSE_STATUS',


  // Saga functions
  getResponses: (query) => ({
    type: responseActions.GET_RESPONSES,
    payload: query,
  }),

  getResponse: responses => ({
    type: responseActions.GET_RESPONSE,
    payload: responses,
  }),
  
  createOrUpdateResponseWatcher: response => {
    return {
      type: responseActions.CREATE_OR_UPDATE_RESPONSE_WATCHER,
      payload: response,
    };
  },

  deleteResponseWatcher: response => {
    return {
      type: responseActions.DELETE_RESPONSE_WATCHER,
      payload: response,
    };
  },

  // Reducer functions
  setResponses: responses => ({
    type: responseActions.SET_RESPONSES,
    payload: responses,
  }),

  setResponse: response => ({
    type: responseActions.SET_RESPONSE,
    payload: response,
  }),

  createResponse: response => {
    return {
      type: responseActions.CREATE_RESPONSE,
      payload: response,
    };
  },
  updateResponse: response => {
    return {
      type: responseActions.UPDATE_RESPONSE,
      payload: response,
    };
  },
  deleteResponse: response => {
    return {
      type: responseActions.DELETE_RESPONSE,
      payload: response,
    };
  },
  resetResponseStatus: () => {
    return {
      type: responseActions.RESET_RESPONSE_STATUS,
    };
  },
};

export default responseActions;
