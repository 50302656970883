// saga.js
import { all, takeEvery, put } from 'redux-saga/effects';
import SuperFetch from '@iso/lib/helpers/superFetch';

import campaignActions from './actions';

const DEFAULT_QUERY = {
  limit: 10,
  skip: 0,
  populate: [],
  sort: '_id',
  direction: 'desc',
};

function* getCampaigns({ payload }) {
  try {
    const query = {
      ...DEFAULT_QUERY,
      ...payload,
    };

    const [campaigns, total] = yield all([
      SuperFetch.get('/campaigns', query),
      SuperFetch.get('/campaigns/count', query),
    ]);

    yield put(campaignActions.setCampaigns({
      campaigns,
      total: total.count,
    }));
  } catch (err) {
    yield put({
      type: campaignActions.CAMPAIGN_ERROR,
      payload: err.message,
    }); 
  }
}

function* getCampaign({ payload: id }) {
  try {
    const campaign = yield SuperFetch.get(`/campaigns/${id}?populate=assignUsers`);

    yield put(campaignActions.setCampaign(campaign));
  } catch (err) {
    yield put({
      type: campaignActions.CAMPAIGN_ERROR,
      payload: err.message,
    }); 
  }
}

function* getCampaignCompanies({ payload: id }) {
  try {
    const companies = yield SuperFetch.get(`/campaigns/${id}/companies`);

    yield put(campaignActions.setCampaignCompanies(companies));
  } catch (err) {
    yield put({
      type: campaignActions.CAMPAIGN_ERROR,
      payload: err.message,
    }); 
  }
}

function* createOrUpdateCampaign({ payload }) {
  try {
    const { id, ...restBody } = payload;
    if (!id) {
      // Create one
      const campaign = yield SuperFetch.post('/campaigns', payload);
      return yield put(campaignActions.createCampaign(campaign));
    }

    // Update existed one
    const campaign = yield SuperFetch.put(`/campaigns/${payload.id}`, restBody);
    return yield put(campaignActions.updateCampaign(campaign));
  } catch (err) {
    yield put({
      type: campaignActions.CAMPAIGN_ERROR,
      payload: err.message,
    });
  }
}

function* doSurveyCampaign({ payload }) {
  try {
    const { id, ...restBody } = payload;
    if (!id) {
      // Create one
      const response = yield SuperFetch.post('/responses', payload);
      return yield put(campaignActions.doSurveyCampaign({
        id: response.id,
        ...restBody,
      }));
    }

    // Update existed one
    const response = yield SuperFetch.put(`/responses/${payload.id}`, restBody);
    return yield put(campaignActions.doSurveyCampaign(response));
  } catch (err) {
    yield put({
      type: campaignActions.CAMPAIGN_ERROR,
      payload: err.message,
    });
  }
}

function* deleteCampaign({ payload }) {
  try {
    const campaign = yield SuperFetch.delete(`/campaigns/${payload.id}`);
    return yield put(campaignActions.deleteCampaign(campaign));
  } catch (err) {
    yield put({
      type: campaignActions.CAMPAIGN_ERROR,
      payload: err.message,
    });
  }
}

export default function* campaignSaga() {
  yield all([
    takeEvery(campaignActions.GET_CAMPAIGNS, getCampaigns),
    takeEvery(campaignActions.GET_CAMPAIGN, getCampaign),
    takeEvery(campaignActions.GET_CAMPAIGN_COMPANIES, getCampaignCompanies),
    takeEvery(campaignActions.CREATE_OR_UPDATE_CAMPAIGN_WATCHER, createOrUpdateCampaign),
    takeEvery(campaignActions.DELETE_CAMPAIGN_WATCHER, deleteCampaign),
    takeEvery(campaignActions.DO_SURVEY_CAMPAIGN_WATCHER, doSurveyCampaign),
  ]);
}
