import { nonAccentVietnamese } from '@iso/lib/helpers/utility';

import templateActions from './actions';

const initialState = {
  templates: [],
  template: {},
  isFetched: false,
  errorMessage: '',
  status: '',
};

export default function templateReducer(state = initialState, action) {
  switch (action.type) {
    case templateActions.SET_TEMPLATES:
      return {
        ...state,
        templates: action.payload.map(item => {
          return {
            ...item,
            keyword: `${nonAccentVietnamese(item.name)}`,
          };
        }),
        isFetched: true,
      };
    case templateActions.CREATE_TEMPLATE:
      return {
        ...state,
        templates:[
          {
            ...action.payload,
            keyword: `${nonAccentVietnamese(action.payload.name)}`,
          },
          ...state.templates,
        ],
        status: 'CREATED',
        template: {},
      };
    case templateActions.UPDATE_TEMPLATE:
      return {
        ...state,
        templates: [
          ...state.templates.map(template => {
            return template.id === action.payload.id ? {
              ...template, 
              ...action.payload,
              keyword: `${nonAccentVietnamese(action.payload.name)}`,
            } : template;
          })
        ],
        status: 'UPDATED',
      };
    case templateActions.DELETE_TEMPLATE:
      return {
        ...state,
        templates: state.templates.filter(template => template.id !== action.payload.id),
        status: 'DELETED',
      };
    case templateActions.TEMPLATE_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        isFetched: true,
      };

    case templateActions.RESET_TEMPLATE_STATUS:
      return {
        ...state,
        errorMessage: '',
        status: '',
      };
    default:
      return state;
  }
}
