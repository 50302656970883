// saga.js
import { all, takeEvery, put, select } from 'redux-saga/effects';
import SuperFetch from '@iso/lib/helpers/superFetch';

import ticketActions from './actions';

const selectTickets = state => state.ticket;
const selectAuth = state => state.Auth;

const DEFAULT_QUERY = {
  limit: 20,
  skip: 0,
  populate: [],
  sort: '_id',
  direction: 'desc',
};

// * Company Tickets
function* getTickets({ payload }) {
  try {
    // const { role = 'SubAgent', profile } = yield select(selectAuth);

    // * @TODO: fix here
    // if (role === 'SubAgent') {
    //   ticketQuery = {
    //     ...ticketQuery,
    //     filter: 'subAgent',
    //     subAgent: profile.id,
    //   };
    // } else if (role === 'Agent') {
    //   ticketQuery = {
    //     ...ticketQuery,
    //     filter: 'agent',
    //     agent: profile.id,
    //   };
    // }

    const [tickets, total, users] = yield all([
      SuperFetch.get('/tickets', {
        ...DEFAULT_QUERY,
        ...payload,
      }),
      SuperFetch.get('/tickets/count', {
        ...DEFAULT_QUERY,
        ...payload,
      }),
      SuperFetch.get('/users', {
        ...DEFAULT_QUERY,
        limit: 0,
      })
    ]);

    yield all([
      put(ticketActions.setTickets({
        tickets,
        total: total.count,
      })),
      put(ticketActions.setUsers(users)),
    ]);
  } catch (err) {
    yield put({
      type: ticketActions.TICKET_ERROR,
      payload: err.message,
    }); 
  }
}

function* loadMoreTickets({ payload }) {
  try {
    // const tickets = yield SuperFetch.get('/tickets', query);
    const [tickets, total] = yield all([
      SuperFetch.get('/tickets', {
        ...DEFAULT_QUERY,
        ...payload,
      }),
      SuperFetch.get('/tickets/count', {
        ...DEFAULT_QUERY,
        ...payload,
      }),
    ]);

    yield put(ticketActions.setLoadMoreTickets({
      tickets,
      total: total.count,
    }));
  } catch (err) {
    yield put({
      type: ticketActions.TICKET_ERROR,
      payload: err.message,
    }); 
  }
}

function* getTicket({ payload: ticketId }) {
  try {
    const ticket = yield SuperFetch.get(`/tickets/${ticketId}`);
    const users = yield SuperFetch.get('/users', {
      ...DEFAULT_QUERY,
      limit: 0,
    });
    const conversations = yield SuperFetch.get('/conversations', {
      ...DEFAULT_QUERY,
      limit: 0,
      direction: 'asc',
      filter: 'ticket',
      ticket: ticketId,
    });

    yield all([
      put(ticketActions.setUsers(users)),
      put(ticketActions.setTicket(ticket)),
      put(ticketActions.setConversations(conversations)),
    ]);
  } catch (err) {
    yield put({
      type: ticketActions.TICKET_ERROR,
      payload: err.message,
    }); 
  }
}

function* updateTicket({ payload }) {
  try {
    const { id, ...restBody } = payload;

    // Update existed one
    const ticket = yield SuperFetch.put(`/tickets/${payload.id}`, restBody);
    return yield put(ticketActions.updateTicket(ticket));
  } catch (err) {
    yield put({
      type: ticketActions.TICKET_ERROR,
      payload: err.message,
    });
  }
}

function* deleteTicket({ payload }) {
  try {
    const ticket = yield SuperFetch.delete(`/tickets/${payload.id}`);
    return yield put(ticketActions.deleteTicket(ticket));
  } catch (err) {
    yield put({
      type: ticketActions.TICKET_ERROR,
      payload: err.message,
    });
  }
}

function* createOrReplyTicket({ payload }) {
  try {
    const { id, formData } = payload;
    if (!id) {
      // Create one
      const result = yield SuperFetch.post('/tickets', formData, true);
      const isForwarding = !!result.ticket;
      if (isForwarding) {
        // Update to conversations
        return yield put(ticketActions.replyTicket(result));
      }

      return yield put(ticketActions.createTicket(result));
    }

    // Reply existed one
    const result = yield SuperFetch.post(`/tickets/${payload.id}/reply`, formData, true);
    return yield put(ticketActions.replyTicket(result));
  } catch (err) {
    yield put({
      type: ticketActions.TICKET_ERROR,
      payload: err.message,
    });
  }
}

export default function* ticketSaga() {
  yield all([
    // * Tickets
    takeEvery(ticketActions.GET_TICKETS, getTickets),
    takeEvery(ticketActions.LOAD_MORE_TICKETS, loadMoreTickets),
    takeEvery(ticketActions.UPDATE_TICKET_WATCHER, updateTicket),
    takeEvery(ticketActions.DELETE_TICKET_WATCHER, deleteTicket),

    takeEvery(ticketActions.GET_TICKET, getTicket),
    takeEvery(ticketActions.CREATE_OR_REPLY_TICKET_WATCHER, createOrReplyTicket),
  ]);
}
