const clientSurveyActions = {
  // SAGA
  GET_CLIENT_SURVEY: 'GET_CLIENT_SURVEY',
  UPDATE_CLIENT_SURVEY_WATCHER: 'UPDATE_CLIENT_SURVEY_WATCHER',

  // REDUCER
  SET_CLIENT_SURVEY: 'SET_CLIENT_SURVEY',

  UPDATE_CLIENT_SURVEY: 'UPDATE_CLIENT_SURVEY',

  // SEARCH
  CLIENT_SURVEY_ERROR: 'CLIENT_SURVEY_ERROR',
  RESET_CLIENT_SURVEY_STATUS: 'RESET_CLIENT_SURVEY_STATUS',


  // Saga functions
  getClientSurvey: payload => ({
    payload,
    type: clientSurveyActions.GET_CLIENT_SURVEY,
  }),
  
  updateClientSurveyWatcher: clientSurvey => {
    return {
      type: clientSurveyActions.UPDATE_CLIENT_SURVEY_WATCHER,
      payload: clientSurvey,
    };
  },

  // Reducer functions
  setClientSurvey: clientSurvey => ({
    type: clientSurveyActions.SET_CLIENT_SURVEY,
    payload: clientSurvey,
  }),

  updateClientSurvey: clientSurvey => {
    return {
      type: clientSurveyActions.UPDATE_CLIENT_SURVEY,
      payload: clientSurvey,
    };
  },
  resetClientSurveyStatus: () => {
    return {
      type: clientSurveyActions.RESET_CLIENT_SURVEY_STATUS,
    };
  },
};

export default clientSurveyActions;
