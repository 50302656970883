import jwtDecode from 'jwt-decode';
import SuperFetch from './superFetch';

class AuthHelper {
  login = async userInfo => {
    if (!userInfo.email || !userInfo.password) {
      return { error: 'Vui lòng nhập email và mật khẩu' };
    }

    const errorMessage = 'Email hoặc mật khẩu không đúng';

    try {
      const userProfile = await SuperFetch.post('/auth/sign-in', userInfo);
      // if (userProfile.token) {
      //   return this.checkExpirity(userProfile.token);
      // }

      return userProfile;
    } catch (err) {
      throw new Error(err.message || errorMessage);
    }
  };

  async getUserProfile() {
    const errorMessage = 'Phiên làm việc đã hết hạn, vui lòng đăng nhập lại';
    try {
      const userProfile = await SuperFetch.get('/auth/profile');
      // if (userProfile.token) {
      //   return this.checkExpirity(userProfile.token);
      // }
      return userProfile;
    } catch (err) {
      throw new Error(err.message || errorMessage);
    }
  }

  async checkDemoPage(token) {
    if (this.checkExpirity(token).error) {
      return { error: 'Token expired' };
    }
    return await SuperFetch.get('secret/test', { token })
      .then(response => ({
        status: '200',
        message: 'Success',
      }))
      .catch(error => ({ error: JSON.stringify(error) }));
  }
  checkExpirity = token => {
    if (!token) {
      return {
        error: 'not matched',
      };
    }
    
    try {
      const profile = jwtDecode(token);

      const expiredAt = profile.expiredAt || profile.exp * 1000;

      if (expiredAt > new Date().getTime()) {
        return {
          ...profile,
          token,
          expiredAt: new Date(expiredAt),
        };
      } else {
        return { error: 'Token expired' };
      }
    } catch (e) {
      console.log(e);

      return { error: 'Server Error' };
    }
  };
}
export default new AuthHelper();
