const campaignActions = {
  // SAGA
  GET_CAMPAIGNS: 'GET_CAMPAIGNS',
  GET_CAMPAIGN: 'GET_CAMPAIGN',
  GET_CAMPAIGN_COMPANIES: 'GET_CAMPAIGN_COMPANIES',
  
  CREATE_OR_UPDATE_CAMPAIGN_WATCHER: 'CREATE_OR_UPDATE_CAMPAIGN_WATCHER',
  DO_SURVEY_CAMPAIGN_WATCHER: 'DO_SURVEY_CAMPAIGN_WATCHER',
  DELETE_CAMPAIGN_WATCHER: 'DELETE_CAMPAIGN_WATCHER',

  // REDUCER
  SET_CAMPAIGNS: 'SET_CAMPAIGNS',
  SET_CAMPAIGN: 'SET_CAMPAIGN',
  SET_CAMPAIGN_USERS: 'SET_CAMPAIGN_USERS',
  SET_CAMPAIGN_COMPANIES: 'SET_CAMPAIGN_COMPANIES',

  CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
  UPDATE_CAMPAIGN: 'UPDATE_CAMPAIGN',
  DELETE_CAMPAIGN: 'DELETE_CAMPAIGN',
  DO_SURVEY_CAMPAIGN: 'DO_SURVEY_CAMPAIGN',

  // SEARCH
  CAMPAIGN_ERROR: 'CAMPAIGN_ERROR',
  RESET_CAMPAIGN_STATUS: 'RESET_CAMPAIGN_STATUS',


  // Saga functions
  getCampaigns: (query) => ({
    type: campaignActions.GET_CAMPAIGNS,
    payload: query,
  }),

  getCampaign: campaign => ({
    type: campaignActions.GET_CAMPAIGN,
    payload: campaign,
  }),

  getCampaignCompanies: companies => ({
    type: campaignActions.GET_CAMPAIGN_COMPANIES,
    payload: companies,
  }),
  
  createOrUpdateCampaignWatcher: campaign => {
    return {
      type: campaignActions.CREATE_OR_UPDATE_CAMPAIGN_WATCHER,
      payload: campaign,
    };
  },
  doSurveyCampaignWatcher: campaign => {
    return {
      type: campaignActions.DO_SURVEY_CAMPAIGN_WATCHER,
      payload: campaign,
    };
  },

  deleteCampaignWatcher: campaign => {
    return {
      type: campaignActions.DELETE_CAMPAIGN_WATCHER,
      payload: campaign,
    };
  },

  // Reducer functions
  setCampaigns: campaigns => ({
    type: campaignActions.SET_CAMPAIGNS,
    payload: campaigns,
  }),

  setCampaign: campaign => ({
    type: campaignActions.SET_CAMPAIGN,
    payload: campaign,
  }),

  setCampaignUsers: users => ({
    type: campaignActions.SET_CAMPAIGN_USERS,
    payload: users,
  }),

  setCampaignCompanies: companies => ({
    type: campaignActions.SET_CAMPAIGN_COMPANIES,
    payload: companies,
  }),

  doSurveyCampaign: response => {
    return {
      type: campaignActions.DO_SURVEY_CAMPAIGN,
      payload: response,
    };
  },
  createCampaign: campaign => {
    return {
      type: campaignActions.CREATE_CAMPAIGN,
      payload: campaign,
    };
  },
  updateCampaign: campaign => {
    return {
      type: campaignActions.UPDATE_CAMPAIGN,
      payload: campaign,
    };
  },
  deleteCampaign: campaign => {
    return {
      type: campaignActions.DELETE_CAMPAIGN,
      payload: campaign,
    };
  },
  resetCampaignStatus: () => {
    return {
      type: campaignActions.RESET_CAMPAIGN_STATUS,
    };
  },
};

export default campaignActions;
